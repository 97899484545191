/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        jQuery(document).ready(function (e) {

          // Header Scroll

            $(window).on("scroll", function() {
              if($(window).scrollTop() > 50) {
                  $(".header").addClass("active");
              } else {
                  //remove the background property so it comes transparent again (defined in your css)
                $(".header").removeClass("active");
              }
            });

        });

        

        
        if (jQuery(window).width() < 992) {

        ///mobile responsive menu full height jQuery////

        var height = $(window).height();

        jQuery('.mega-menu').css({'height': height, 'overflow-y':'scroll'});

        }


        // Main slider

        $('.slider').slick({
    
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          // autoplaySpeed: 3000,
          // autoplay: true,
          fade: true,
          cssEase: 'ease-in-out',
          touchThreshold: 1000,
          easing:'linear'
          // prevArrow: '.button-prev'
          // nextArrow: '.button-next'
    
        });


        // jQuery('.mission-slider').slick({

        //   dots: true,
        //   arrows: true,
        //   infinite: true,
        //   slidesToScroll: 1,
        //   autoplay: true,
        //   autoplaySpeed: 3000,
        
        //   });

          $('.mission-slider').slick({
    
            arrows: true,
            infinite: true,
            autoplaySpeed: 8000,
            autoplay: true,
            fade: true,
            cssEase: 'ease-in-out',
            touchThreshold: 1000,
            easing:'linear',
            responsive: [{
              breakpoint: 767,
              settings: {
                arrows: false,
              }
            }]
      
          });

          
          $('.hair-o-thon-slider').slick({
    
            arrows: true,
            infinite: true,
            autoplaySpeed: 3000,
            autoplay: true,
            fade: true,
            cssEase: 'ease-in-out',
            touchThreshold: 1000,
            easing:'linear',
            responsive: [{
              breakpoint: 767,
              settings: {
                arrows: false,
              }
            }]
      
          });


          $('.testimonial-slider').slick({
    
            arrows: false,
            dots: true,
            infinite: true,
            autoplaySpeed: 3000,
            // autoplay: true,
            fade: true,
            cssEase: 'ease-in-out',
            touchThreshold: 1000,
            easing:'linear'
      
          });

          
          $('.treatment-testi-slider').slick({
    
            arrows: true,
            dots: false,
            infinite: true,
            autoplaySpeed: 3000,
            autoplay: true
      
          });

          jQuery('.slicktab').slick({
      
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:false,
            arrows: true,
            // prevArrow: '.button-prev',
            // nextArrow: '.button-next',
            autoplay:false
          
          });

          jQuery('.video-slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            // fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            // autoplay: true,
            // cssEase: 'linear',
            asNavFor: '.slider-nave'
      
          });
      
          jQuery('.slider-nave').slick({

            slidesToShow: 2,
            slidesToScroll: 1,
            asNavFor: '.video-slider',
            dots: false,
            focusOnSelect: true

          });
              

          jQuery(document).ready(function () {

            jQuery('.expanding-grid').expandingGrid();

          });

          jQuery(document).ready(function(){
        
            window.onload = function() {
              // alert ("test");
              
              // jQuery( "#a-0").addClass( "active" );
              // jQuery( "#section1-0").addClass( "expanded" );
              // jQuery(".expanding-grid").find("ul").append("<span/>");     
  
            };
        
          });

          // Clinic Locator


          jQuery('body').on( 'click','.filter-form-wrap .filter-cta' ,function(e){

            //e.preventDefault();
  
            debugger;
           
            jQuery('.wp-pagenavi').addClass('removeme');
  
            var states = jQuery('.fill-States').val();
            var cities = jQuery('.fill-Cities').val();
            var locality = jQuery('.fill-Locality').val();
           
  
            //alert(states);
            //alert(cities);
            //alert(locality);
  
            jQuery.ajax({
  
              url: ajaxurl,
              type: 'post',
              data:{
  
                action: 'home_filter_ajax_response',
                
  
                'states':states,
                'cities':cities,
                'locality':locality
                
           
  
              },
  
             
              success: function(data) {
  
               
  
                 //jQuery('.ajax-loader').fadeOut();
  
                // Down smooth Scroll 
              
              var $root = jQuery('html, body');   
              
              var position = parseInt( jQuery( jQuery('.mrb_features').offset().top ));
              $root.animate({
  
              scrollTop: position
  
              }, 500); 
    
            
  
  
                var data = jQuery(data);
                //data
  
                jQuery('.ajax-wrap').css({
                  '-webkit-transform' : 'scale(1)',
                  '-moz-transform'    : 'scale(1)',
                  '-ms-transform'     : 'scale(1)',
                  '-o-transform'      : 'scale(1)',
                  'transform'         : 'scale(1)'
                }).html( data );//.find('.animate-ajax').animate({zoom:'150%'}, 2000);
               //.animate({transform: '0%'});
  
               //data.siblings('.animate-ajax ').fadeIn(3000);
   
  
              },
              error: function(err) {
  
                // console.log(err);
  
              }
  
            });
  
  
          });

            
        jQuery(document).ready(function (e) {

              // Down smooth Scroll 
            
              var $root = jQuery('html, body');
  
  
              jQuery('.scroll-down').click(function() {
      
                var position = parseInt( jQuery( jQuery('#first') ).offset().top )-120;
      
                  $root.animate({
      
                      scrollTop: position
      
                  }, 500);
      
                  return false;
      
              });

            });
            


            jQuery('.read_moretext').click(function() {

              jQuery('.text-readmore').slideToggle(); 
          
              if(jQuery(this).hasClass('open')){
                
                jQuery(this).removeClass('open').html('Read More');  
          
                } else {  
          
                jQuery(this).addClass('open').html('Read Less');
          
                }
                return false;
             
            });


            jQuery(document).ready(function(){

              jQuery(".btn-show").click(function(){
      
                  var editid =jQuery(this).parent();
                  
                  jQuery(editid).find(".para").toggle(500);
      
              });
      
          });

          jQuery(document).ready(function(){

          $('.user-flow .links li .flow2').click(function(){

            window.location.href='/richfeel.com/clinic-locator/';

         })

        });


          // jQuery(document).ready(function() {    
          // var searchUsers = document.querySelector('#search-users'),
          //     users = document.querySelectorAll('.user'),
          //     usersData = document.querySelectorAll('.user-data'),
          //     searchVal;

          // searchUsers.addEventListener('keydown', function() {
          //   searchVal = this.value.toLowerCase();
            
          //   for (var i = 0; i < users.length; i++) {
          //     if (!searchVal || usersData[i].textContent.toLowerCase().indexOf(searchVal) > -1) {
          //       users[i].style['display'] = 'flex';
          //     }
          //     else {
          //       users[i].style['display'] = 'none';
          //     }
          //   }
          // });

          // });

          // jQuery(document).ready(function() {    
          // var searchUsers = document.querySelector('#search-user'),
          //     users = document.querySelectorAll('.users'),
          //     usersData = document.querySelectorAll('.user-datas'),
          //     searchVal;

          // searchUsers.addEventListener('keydown', function() {
          //   searchVal = this.value.toLowerCase();
            
          //   for (var i = 0; i < users.length; i++) {
          //     if (!searchVal || usersData[i].textContent.toLowerCase().indexOf(searchVal) > -1) {
          //       users[i].style['display'] = 'flex';
          //     }
          //     else {
          //       users[i].style['display'] = 'none';
          //     }
          //   }
          // });

          // });




          jQuery(document).ready(function() {  

            jQuery('[data-search]').on('keyup', function() {
              var searchVal = jQuery(this).val();
              var filterItems = jQuery('[data-filter-item]');
            
              if ( searchVal != '' ) {
                filterItems.addClass('hidden');
                jQuery('[data-filter-item][data-filter-name*="' + searchVal.toLowerCase() + '"]').removeClass('hidden');
              } else {
                filterItems.removeClass('hidden');
                error = "Number is required";
              }
            });
            
            });











          




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
